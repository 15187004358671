@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.ocean {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #B7D4EA;
  left: 0;
  height: 0;
}


.wave {
  /* background: url('https://cdn.kcak11.com/codepen_assets/wave_animation/wave.svg') repeat-x; */
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='600' %3E%3Cdefs%3E%3C/defs%3E%3Cpath fill='%23d0eef7' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0) scale(1 1.2)'/%3E%3C/svg%3E");
  /* background-size: 60rem 253px; */
  position: fixed;
  /* top: -160px; */
  /* bottom: -130; */
  max-width: 56rem;
  width: 100%;
  height: 400px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.6;
  z-index: 0;
}


.wave:nth-of-type(2) {
  /* bottom: -100; */
  animation: wave 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 5s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(3) {
  /* bottom: -130; */
  animation: wave 13s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.325s infinite, swell 10s ease -1.25s infinite;
  opacity: 0.4;
}



@keyframes wave {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 100rem;
  }
}

/* @keyframes swell {

  0%,
  100% {
      transform: translate3d(0, -25px, 0);
  }

  50% {
      transform: translate3d(0, 5px, 0);
  }
} */

.endWave {
  display: none;
}

.lineItem {
  width: 292px;
  height: 2px;
  background: linear-gradient(90deg, rgba(86, 204, 242, 0) 0%, #1313FF 49.53%, rgba(86, 204, 242, 0) 100%);
}